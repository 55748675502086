import React from 'react';

const PercentField = (props) => {
    return <div id={props.id} style={{maxWidth: '400px', marginBottom: '20px'}}>
        <span>{props.labelText}</span>
        {props.tooltip && props.tooltip}
        <div className='pds-input-icon-right' style={{marginTop: '8px'}}>
            <input
                aria-label={props.labelText}
                className='form-control'
                maxLength={3}
                type='text'
                value={props.value}
                onChange={props.onChange}
            />
            <i data-feather='percent' aria-hidden='true' width='16px'/>
        </div>
    </div>;
};

export default PercentField;