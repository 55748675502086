import {StateUtil} from '../../util/StateUtil';
import {getData} from '../repository/DataRepository';
import {UPDATE_RESULTS} from '../reducer/ResultsReducer';

export const retrieveResults = (employeeRange, industry, zipCode, role) => {
    return async (dispatch, getState) => {
        try {
            dispatch({type: UPDATE_RESULTS, data: {loading: true}});
            const resultData = await getData({
                id: getState().id,
                employeeRange,
                industry,
                region: StateUtil.getState(zipCode).region,
                role
            });
            dispatch({type: UPDATE_RESULTS, data: {...resultData, error: false, loading: false}});
        } catch (error) {
            dispatch({type: UPDATE_RESULTS, data: {error: true, loading: false}});
        }
    };
};