import React from 'react';
import Chart from 'react-apexcharts';

const BarGraph = (props) => {
    let others, you;

    const render = () => {
        return <div className='mixed-chart'>
            <Chart
                options={getOptions()}
                series={getSeries(others, you)}
                type='bar'
                width={setChartWidth()}
            />
        </div>;
    };

    const setChartWidth = () => {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            return '250';
        } else {
            return '400';
        }
    };

    const trimDollarSign = () => {
        return [
            props.apiResults.replace(/[$,]+/g, ''),
            props.userAnswers && props.userAnswers.replace(/[$,]+/g, '')
        ];
    };

    const trimPercentSign = () => {
        return [
            props.apiResults.replace(/[%,]+/g, ''),
            props.userAnswers && props.userAnswers.replace(/[%,]+/g, '')
        ];
    };

    const getOptions = () => ({
        chart: {
            id: 'basic-bar',
            toolbar: {show: false},
        },
        tooltip: {
            enabled: false
        },
        xaxis: {
            categories: ['Others', 'You'],
        },
        dataLabels: {
            enabled: false
        },
        legend: {show: false}
    });

    const getSeries = (others, you) => {
        return [
            {
                name: 'Others',
                color: '#0061A0',
                data: [others]
            }, {
                name: 'You',
                color: '#0091DA',
                data: [you]
            }
        ];
    };

    if (props.apiResults.includes('$')) {
        [others, you] = trimDollarSign();
    } else {
        [others, you] = trimPercentSign();
    }
    return render();
};

export default BarGraph;