import {postData} from '../repository/DataRepository';

export const saveData = (action) => {
    return async (dispatch, getState) => {
        dispatch(action);
        await postData({
            ...action.data,
            id: getState().id
        });
    };
};