export const ZERO = '$0';
export const FIVE_THOUSAND = '$5,000';
export const TEN_THOUSAND = '$10,000';
export const FIFTEEN_THOUSAND = '$15,000';
export const TWENTY_THOUSAND = '$20,000';
export const TWENTY_FIVE_THOUSAND = '$25,000';

export const childBenefitOptions = [
    {display: 'Please select', value: ''},
    {display: ZERO, value: ZERO},
    {display: FIVE_THOUSAND, value: FIVE_THOUSAND},
    {display: TEN_THOUSAND, value: TEN_THOUSAND},
    {display: FIFTEEN_THOUSAND, value: FIFTEEN_THOUSAND},
    {display: TWENTY_THOUSAND, value: TWENTY_THOUSAND},
    {display: TWENTY_FIVE_THOUSAND, value: TWENTY_FIVE_THOUSAND}
];

