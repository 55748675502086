import React from 'react';
import EnterpriseHeader from '../bookend/EnterpriseHeader';
import EnterpriseFooter from '../bookend/EnterpriseFooter';
import ResultsView from './ResultsView';
import {useSelector} from 'react-redux';
import {COMPANY_INFO_PAGE, RESULT_PAGE} from '../../redux/reducer/PageReducer';
import {pageSelector} from '../../redux/selectors/PageSelector';
import PrincipalFooterInfo from '../bookend/PrincipalFooterInfo';
import CompanyInfoForm from '../form/CompanyInfoForm';
import {getDataLayer} from '../../util/dataLayer';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const Welcome = () => {
    const currentPage = useSelector(pageSelector);

    const checkPage = (path, pageTitle, page) => {
        if (currentPage === page) {
            const eventString =
                getDataLayer().filter(layer => (layer.event === 'pageViewLoaded')).length > 0
                    ? 'virtualPageChange'
                    : 'pageViewLoaded';
            GoogleAnalyticsUtil.pageEventAction(path, pageTitle, eventString);
            window.history.replaceState(null, '', `/benefit-selection${path}`);
            return true;
        }
        return false;
    };

    return <div style={{position: 'relative', minHeight: '75vh'}}>
        <EnterpriseHeader/>
        {checkPage('/company', 'Company Info', COMPANY_INFO_PAGE) && <CompanyInfoForm/>}
        {checkPage('/results', 'Results', RESULT_PAGE) && <ResultsView/>}
        <PrincipalFooterInfo/>
        <EnterpriseFooter/>
    </div>;
};

export default Welcome;
