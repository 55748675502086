import React from 'react';
import {getCalloutText} from '../../../util/CalloutText';

const BenefitCallout = ({benefit, coverage, answer, result}) => {
    const render = () => {
        const calloutText = getCalloutText(coverage, benefit, answer, result);
        return Boolean(calloutText) && <section
            className='pds-calloutSection pds-calloutSection-backgroundLight'
            style={{padding: '10px'}}>
            <p style={{fontSize: '16px'}}>{calloutText}</p>
        </section>;
    };

    return render();
};

export default BenefitCallout;