import React from 'react';
import DropDownField from './common/DropDownField';
import {useDispatch, useSelector} from 'react-redux';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {appendString, numeric} from '../../util/StringUtil';
import {orthodontiaCoverage} from '../../properties/orthodontiaCoverage';
import {UPDATE_DENTAL_INSURANCE} from '../../redux/reducer/DentalInsuranceReducer';
import Button from './common/Button';
import Modal from './common/Modal';
import CurrencyField from './common/CurrencyField';
import PercentField from './common/PercentField';
import CurrencyUtil from '../../util/CurrencyUtil';
import {saveData} from '../../redux/action/SaveDataAction';
import Tooltip from './common/Tooltip';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const DentalInsurance = () => {

    const dispatch = useDispatch();
    const reduxState = useSelector(stateSelector);
    const [state, setState] = React.useState({
        preventiveDeductibles: reduxState.dentalInsurance.preventiveDeductibles,
        basicDeductibles: reduxState.dentalInsurance.basicDeductibles,
        majorDeductibles: reduxState.dentalInsurance.majorDeductibles,
        preventiveCareCoveredByInsurance: reduxState.dentalInsurance.preventiveCareCoveredByInsurance,
        basicCareCoveredByInsurance: reduxState.dentalInsurance.basicCareCoveredByInsurance,
        majorCareCoveredByInsurance: reduxState.dentalInsurance.majorCareCoveredByInsurance,
        benefitMaximum: reduxState.dentalInsurance.benefitMaximum,
        orthodontiaCoverage: reduxState.dentalInsurance.orthodontiaCoverage,
    });

    const render = () => {
        return (
            <div className='noPrint'>
                <Modal
                    id='dentalForm'
                    buttonText='Compare your insurance'
                    headerText='Dental insurance:'
                    srText='dental'
                    title='Dental insurance'
                >
                    <div style={{textAlign: 'left', marginTop: '40px'}}>
                        <h5>Deductibles
                        <Tooltip content={'This is the amount an employee pays for covered dental services before the insurance company pays benefits'}/></h5>
                        <CurrencyField
                            id='DentalDeductiblePreventativeService'
                            value={state.preventiveDeductibles}
                            labelText={'What\'s the deductible for preventive services?'}
                            onChange={(value) => setState({...state, preventiveDeductibles: CurrencyUtil.formatUSD(value)})}
                        />
                        <CurrencyField
                            id='DentalDeductibleBasicService'
                            value={state.basicDeductibles}
                            labelText={'What\'s the deductible for basic services?'}
                            onChange={(value) => setState({...state, basicDeductibles: CurrencyUtil.formatUSD(value)})}
                        />
                        <CurrencyField
                            id='DentalDeductibleMajorService'
                            value={state.majorDeductibles}
                            labelText={'What\'s the deductible for major services?'}
                            onChange={(value) => setState({...state, majorDeductibles: CurrencyUtil.formatUSD(value)})}
                        />
                        <h5>Coinsurance</h5>
                        <PercentField
                            id='DentalCoinsurancePreventiveService'
                            value={state.preventiveCareCoveredByInsurance}
                            labelText='What percent does the insurance company pay for covered preventive services?'
                            onChange={(e) => setState({...state, preventiveCareCoveredByInsurance: numeric(e.target.value)})}
                        />
                        <PercentField
                            id='DentalCoinsuranceBasicService'
                            value={state.basicCareCoveredByInsurance}
                            labelText='What percent does the insurance company pay for covered basic services?'
                            onChange={(e) => setState({...state, basicCareCoveredByInsurance: numeric(e.target.value)})}
                        />
                        <PercentField
                            id='DentalCoinsuranceMajorService'
                            value={state.majorCareCoveredByInsurance}
                            labelText='What percent does the insurance company pay for covered major services?'
                            onChange={(e) => setState({...state, majorCareCoveredByInsurance: numeric(e.target.value)})}
                        />
                        <CurrencyField
                            id='DentalBenefitMaximum'
                            labelText={'What\'s the benefit maximum?'}
                            value={state.benefitMaximum}
                            onChange={(value) => setState({...state, benefitMaximum: CurrencyUtil.formatUSD(value)})}
                            tooltip={<Tooltip content={'the annual maximum amount of benefits paid per individual'}/>}
                        />
                        <DropDownField
                            id='DentalOrthodontiaCoverage'
                            options={orthodontiaCoverage}
                            value={state.orthodontiaCoverage}
                            isValid={true}
                            labelText='Who gets orthodontia coverage?'
                            onChange={(e) => setState({...state, orthodontiaCoverage: e.target.value})}
                        />
                    </div>
                    <Button
                        className='pds-button pds-button-primary pds-modal-close-dialog'
                        onClick={() => {
                            GoogleAnalyticsUtil.modalClickAction('Dental Insurance', 'Submit');
                            dispatch(saveData(
                                {
                                    type: UPDATE_DENTAL_INSURANCE,
                                    data: {
                                        ...state,
                                        preventiveCareCoveredByInsurance: appendString(state.preventiveCareCoveredByInsurance, '%'),
                                        basicCareCoveredByInsurance: appendString(state.basicCareCoveredByInsurance, '%'),
                                        majorCareCoveredByInsurance: appendString(state.majorCareCoveredByInsurance, '%'),
                                        product: 'Dental'
                                    }
                                }
                            ));
                        }}
                    ><span className='sr-only'>Dental insurance submit</span>Submit</Button>
                </Modal>
            </div>
        );
    };

    return render();
};

export default DentalInsurance;
