import React from 'react';
import {employeeRanges} from '../../properties/employeeRanges';
import DropDownField from './common/DropDownField';
import {industries} from '../../properties/industries';
import {roles} from '../../properties/roles';
import Input from './common/Input';
import {CompanyInfoFormValidator} from '../../validation/CompanyInfoFormValidator';
import {numeric} from '../../util/StringUtil';
import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_COMPANY_INFO} from '../../redux/reducer/CompanyInfoReducer';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {RESULT_PAGE, UPDATE_PAGE} from '../../redux/reducer/PageReducer';
import {retrieveResults} from '../../redux/action/ResultsAction';
import Button from './common/Button';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const CompanyInfoForm = () => {

    const dispatch = useDispatch();
    const reduxState = useSelector(stateSelector);
    const [state, setState] = React.useState({
        employeeRange: reduxState.companyInfo.employeeRange,
        industry: reduxState.companyInfo.industry,
        role: reduxState.companyInfo.role,
        zipCode: reduxState.companyInfo.zipCode,
        employeeRangeIsValid: true,
        industryIsValid: true,
        roleIsValid: true,
        zipCodeIsValid: true
    });

    const render = () => {
        return (
            <div className='container-fluid'>
                <div className='row' style={{margin: '40px 20px'}}>
                    <div className='col-md-6 offset-md-3'>
                        <h2>Stay competitive by offering benefits to help attract and retain employees</h2>
                        <p>Even if you don’t currently offer employee benefits, find out what other small businesses
                            offer.
                            This comparison tool is designed for any employer, for-profit or non-profit, with 3-100
                            employees.</p>
                        <p>Answer these questions about your organization and get immediate results.
                            The report will show the benefits similarly sized organizations in your industry and region
                            offer. This information won't be used for other purposes or shared with other organizations.</p>
                        <div className='col-md-6 offset-md-3' style={{marginTop: '40px', paddingLeft: 0, paddingRight: 0}}>
                            <DropDownField
                                id='CompanyInfoEmployeeRangeDropdown'
                                options={employeeRanges}
                                value={state.employeeRange}
                                isValid={state.employeeRangeIsValid}
                                labelText='How many employees do you have who are eligible for benefits?'
                                errorText='Please select a range.'
                                onChange={(e) => setState({...state, employeeRange: e.target.value})}
                            />
                            <DropDownField
                                id='CompanyInfoIndustryDropdown'
                                options={industries}
                                value={state.industry}
                                isValid={state.industryIsValid}
                                labelText='Which industry best describes your organization?'
                                errorText='Please select an industry.'
                                onChange={(e) => setState({...state, industry: e.target.value})}
                            />
                            <DropDownField
                                id='CompanyInfoRoleDropdown'
                                options={roles}
                                value={state.role}
                                isValid={state.roleIsValid}
                                labelText='What best describes your role?'
                                errorText='Please select a role.'
                                onChange={(e) => setState({...state, role: e.target.value})}
                            />
                            <Input
                                id='CompanyInfoZipCodeInput'
                                value={state.zipCode}
                                isValid={state.zipCodeIsValid}
                                maxLength={5}
                                labelText={'What\'s your ZIP code?'}
                                errorText='Please enter a valid U.S. ZIP code.'
                                onChange={(e) => setState({...state, zipCode: numeric(e.target.value)})}
                            />
                            <Button
                                id='CompanyInfoNextButton'
                                onClick={() => {
                                    GoogleAnalyticsUtil.callToAction('next');
                                    window.scrollTo(0, 0);
                                    CompanyInfoFormValidator.validate(state, setState) && dispatchData();
                                }}
                                next
                            >Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const dispatchData = () => {
        const data = {
            employeeRange: state.employeeRange,
            industry: state.industry,
            role: state.role,
            zipCode: state.zipCode
        };
        dispatch({
            type: UPDATE_COMPANY_INFO,
            data: data
        });
        dispatch({type: UPDATE_PAGE, data: RESULT_PAGE});
        dispatch(retrieveResults(state.employeeRange, state.industry, state.zipCode, state.role));
    };

    return render();
};

export default CompanyInfoForm;
