export const SELECT_PRODUCT_PAGE = 1;
export const COMPANY_INFO_PAGE = 2;
export const RESULT_PAGE = 3;
export const UPDATE_PAGE = 'UPDATE_PAGE';
const defaultState = COMPANY_INFO_PAGE;

const pageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_PAGE:
            return action.data;
        default:
            return state;
    }
};

export default pageReducer;