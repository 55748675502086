import React from 'react';
import DropDownField from './common/DropDownField';
import {useDispatch, useSelector} from 'react-redux';
import Input from './common/Input';
import {appendString, numeric} from '../../util/StringUtil';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {benefitPercentageOptions} from '../../properties/benefitPercentageOptions';
import {benefitDurations} from '../../properties/benefitDurations';
import {occupationPeriods} from '../../properties/occupationPeriods';
import {UPDATE_LONG_TERM_DISABILITY} from '../../redux/reducer/LongTermDisabilityReducer';
import Button from './common/Button';
import CurrencyField from './common/CurrencyField';
import CurrencyUtil from '../../util/CurrencyUtil';
import Modal from './common/Modal';
import {saveData} from '../../redux/action/SaveDataAction';
import Tooltip from './common/Tooltip';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const LongTermDisability = () => {

    const dispatch = useDispatch();
    const reduxState = useSelector(stateSelector);
    const [state, setState] = React.useState({
        benefitPercent: reduxState.longTermDisability.benefitPercent,
        maximumMonthlyBenefit: reduxState.longTermDisability.maximumMonthlyBenefit,
        benefitDuration: reduxState.longTermDisability.benefitDuration,
        eliminationPeriod: reduxState.longTermDisability.eliminationPeriod,
        ownOccupationPeriod: reduxState.longTermDisability.ownOccupationPeriod
    });

    return (
        <div className='noPrint'>
            <Modal
                id='longTermDisabilityForm'
                buttonText='Compare your insurance'
                headerText='Long-term disability insurance:'
                srText='long term disability'
                title='Long-term disability insurance'
            >
                <div style={{textAlign: 'left', marginTop: '40px'}}>
                    <DropDownField
                        id='LongTermDisabilityBenefitPercentageDropdown'
                        options={benefitPercentageOptions}
                        value={state.benefitPercent}
                        isValid={true}
                        labelText={'What\'s the benefit percent?'}
                        onChange={(e) => setState({...state, benefitPercent: e.target.value})}
                    />
                    <CurrencyField
                        id='LongTermDisabilityMaximumMonthlyBenefitInput'
                        labelText={'What\'s the maximum monthly benefit?'}
                        value={state.maximumMonthlyBenefit}
                        onChange={(value) => setState({...state, maximumMonthlyBenefit: CurrencyUtil.formatUSD(value)})}
                        tooltip={<Tooltip content={'This is the maximum dollar amount the insurance company pays to a disabled employee. A common maximum monthly benefit is $6,000.'}/>}
                    />
                    <DropDownField
                        id='LongTermDisabilityBenefitBenefitDurationDropdown'
                        options={benefitDurations}
                        value={state.benefitDuration}
                        isValid={true}
                        labelText={'What\'s the benefit duration?'}
                        onChange={(e) => setState({...state, benefitDuration: e.target.value})}
                        tooltip={<Tooltip content={'This is the maximum length of time the benefit will be paid.'}/>}
                    />
                    <Input
                        id='LongTermDisabilityEliminationPeriodInput'
                        value={state.eliminationPeriod}
                        isValid={true}
                        maxLength={3}
                        labelText={'What\'s the elimination period in days (must be 30-365 days)?'}
                        onChange={(e) => setState({...state, eliminationPeriod: numeric(e.target.value)})}
                        tooltip={<Tooltip content={'This is the amount of time employees on disability need to wait before they’re eligible to receive benefit payments.'}/>}
                    />
                    <DropDownField
                        id='LongTermDisabilityOccupationPeriodDropdown'
                        options={occupationPeriods}
                        value={state.ownOccupationPeriod}
                        isValid={true}
                        labelText={'What\'s the own occupation period?'}
                        onChange={(e) => setState({...state, ownOccupationPeriod: e.target.value})}
                        tooltip={<Tooltip content={'This determines the degree of a person\'s disability based on the occupation performed at the time of disability.'}/>}
                    />
                </div>
                <Button
                    className='pds-button pds-button-primary pds-modal-close-dialog'
                    onClick={() => {
                        GoogleAnalyticsUtil.modalClickAction('Long-term disability insurance', 'Submit');
                        dispatch(saveData(
                            {
                                type: UPDATE_LONG_TERM_DISABILITY,
                                data: {
                                    benefitPercent: state.benefitPercent,
                                    maximumMonthlyBenefit: state.maximumMonthlyBenefit,
                                    benefitDuration: state.benefitDuration,
                                    eliminationPeriod: appendString(state.eliminationPeriod, ' day(s)'),
                                    ownOccupationPeriod: state.ownOccupationPeriod,
                                    product: 'Long-term disability'
                                }
                            }
                        ));
                    }}
                ><span className='sr-only'>LTD insurance submit</span>Submit</Button>
            </Modal>
        </div>
    );
};

export default LongTermDisability;
