import {numericWithPeriod} from './StringUtil';

const valueMapper = (value) => {
    let mappedResult;
    if (!Boolean(value) || value.toLowerCase().trim() === 'no' || value.toLowerCase().trim() === 'none') {
        mappedResult = '0';
    } else if (value.toLowerCase().trim() === 'yes' || value.toLowerCase().trim() === 'child') {
        mappedResult = '1000';
    } else if (value.toLowerCase().trim() === 'child and adult') {
        mappedResult = '2000';
    } else if (value.toLowerCase().trim() === 'end of benefit duration') {
        mappedResult = '9999';
    } else if (value.toLowerCase().trim().includes('social security normal retirement age')) {
        mappedResult = '9999';
    } else {
        mappedResult = value;
    }
    return parseFloat(numericWithPeriod(mappedResult));
};

export const getCalloutText = (coverage, benefit, answer, result) => {
    let option;
    if (Boolean(answer)) {
        if (valueMapper(answer) === valueMapper(result)) {
            option = 'equal';
        } else if (valueMapper(answer) > valueMapper(result)) {
            option = 'better';
        } else {
            option = 'worse';
        }
        return words && words[coverage] && words[coverage][benefit] && words[coverage][benefit][option];
    }
    return '';
};

export const words = {
    'Dental insurance': {
        preventiveDeductibles: {
            better: 'Proper oral health contributes to a person\'s overall health. Similar organizations have designed dental benefits with lower deductibles, which means slightly richer benefit designs. Consider decreasing the deductible to promote use of preventive services, as well as move more in line with your peers.',
            equal: 'Your deductible is in line with similar organizations. Having average or lower deductibles helps promote usage of preventive services. This is important because proper oral health contributes to a person\'s overall health.',
            worse: 'Proper oral health contributes to a person\'s overall health. With respect to deductibles, your benefits are slightly richer than other similar organizations. Increasing the deductible could reduce the cost of your benefits slightly and still keep your benefits in line with other employers.'
        },
        preventiveCareCoveredByInsurance: {
            better: 'The coinsurance levels of your current benefits are slightly higher than industry averages. Reducing them could help reduce the benefit cost but keep you in line competitively.',
            equal: 'Offering average or higher coinsurance levels encourages employees to continue to seek care. Your coinsurance is in line with similar organizations.',
            worse: 'The dental benefits in place create more patient out-of-pocket costs because of lower coinsurance percentages, when compared to similar organizations. Raising the coinsurance percentages could make the dental offering more attractive to your employees and encourage them to continue to seek care.'
        },
        benefitMaximum: {
            better: 'The benefit maximum you\'re offering is higher than your peers. Reducing it will still provide a valuable benefit but help lower the benefit cost.',
            equal: 'Having an average benefit maximum provides employees with adequate coverage while helping employers balance benefit costs. Your benefit maximum is in line with similar organizations.',
            worse: 'The maximum benefit of your current design is lower than what other organizations are offering. Increasing the maximum will create a richer benefit offering. Or you could consider adding a maximum rollover option that allows employees to carry over a portion of their unused maximum amount.'
        },
        orthodontiaCoverage: {
            better: 'Your orthodontia coverage is above similar organizations. Employees value this benefit for their families and themselves.',
            equal: 'You’re in line with similar organizations Orthodontia coverage is valued by employees for their families and themselves.',
            worse: 'Similar organizations most often offer orthodontia. Employees value this benefit for their families and themselves.'
        }
    },
    'Life insurance': {
        employeeBenefitSchedule: {
            better: '',
            equal: '',
            worse: ''
        },
        maximumEmployeeBenefit: {
            better: 'The maximum benefit you offer is more than what similar organizations provide. Employees appreciate the extra coverage.',
            equal: 'The maximum benefit you offer is comparable with what similar organizations typically provide.',
            worse: 'The maximum benefit you offer is less than what similar organizations provide.  A higher maximum benefit can give employees extra financial protection.'
        },
        waiverOfPremiumDuration: {
            better: 'The length of time premiums will be waived and life insurance benefits continued for a disabled employee is longer than employers similar to you.  Consider shortening the duration to be more in line with similar organizations and to reduce benefit costs.',
            equal: 'The length of time premiums will be waived and life insurance benefits continued for a disabled employee is in line with employers similar to you. A waiver of premium benefit ensures coverage continues and waives the premium for a disabled employee, providing a greater level of protection for your employees.',
            worse: 'The length of time premiums will be waived and life insurance benefits continued for a disabled employee is shorter than other employers similar to you. Consider extending the waiver of premium duration to provide a greater level of protection for your employees.'
        },
        spouseBenefit: {
            better: 'Your spouse benefit is higher than what employers similar to you offer. With many individuals relying solely on coverage provided at work, they appreciate the extra financial protection for themselves and their families.',
            equal: 'Your spouse benefit is like what other similar organizations offer. You have done a great job providing your employees with a base level of spouse coverage to help protect them and their families from the unexpected.',
            worse: 'Your spouse benefit is less than what employers similar to you offer. You may want to consider increasing the benefit to help provide a greater level of financial protection.'
        },
        childBenefit: {
            better: 'Your child benefit is higher than what employers similar to you offer. ',
            equal: 'Your child benefit is in line with other similar organizations.',
            worse: 'Your child benefit is less than what employers similar to you offer.'
        }
    },
    'Short-term disability insurance': {
        benefitPercent: {
            better: 'Similar organizations sometimes offer a lower benefit percent on their STD coverage. You may want to consider decreasing your STD benefit percent to reduce costs and align your coverage with other employers.',
            equal: 'The benefit percent you are currently offering is in line with what other employers offer their employees. You have done a good job creating a benefit that helps meet the income replacement needs of your employees and is in alignment with other organizations.',
            worse: 'Similar organizations typically offer a higher benefit percent on their STD coverage. You may want to increase the benefit percent to be more in line with other employers and to provide a higher level of employee income replacement.'
        },
        maximumWeeklyBenefit: {
            better: 'Your current STD maximum weekly benefit is higher than what is offered by many other organizations. Consider if there would be a significant impact to your employees if it were lowered. Reducing this amount could lead to reduced costs.',
            equal: 'The maximum benefit you currently offer is consistent with the benefit being offered by other employers. The coverage you have created helps meet the income replacement needs of your employees while remaining competitive with other organizations.',
            worse: 'You are currently offering a STD maximum benefit that is below what many other organizations are offering. You might want to consider evaluating this amount to help ensure your employee\'s income replacement needs are satisfied in the event of a disability.'
        },
        benefitDuration: {
            better: 'Your STD benefit duration is longer than what many other employers currently provide. You may want to evaluate this in conjunction with other paid time-off programs and consider reducing the duration to potentially reduce costs.',
            equal: 'The benefit duration that is part of your STD coverage is comparable to that of other employers. The benefit you have created helps meet the disability insurance needs of your employees.',
            worse: 'Your STD benefit duration is shorter than what many other employers currently provide. You may want to evaluate this in conjunction with other paid time-off programs and consider increasing the duration to remain competitive with other organizations.'
        },
        accidentEliminationPeriod: {
            better: 'Have you considered shortening the accident elimination period to be more aligned with other employers? A shorter accident elimination period assists employees during those times when an unforeseeable situation can put an immediate stop to their paycheck.',
            equal: 'This portion of your STD coverage is in line with what is offered by other employers. You have done a good job of creating a benefit that is competitive with similar organizations and still helps meet the needs of your employees.',
            worse: 'Have you considered a longer accident elimination period? Most disabilities are caused by illnesses, not accidents. A longer accident elimination period can help cut costs while still providing a competitive benefit.'
        },
        sicknessEliminationPeriod: {
            better: 'Shortening the sickness elimination period to align with what other organizations offer will help make your STD coverage more competitive in the marketplace and assist your employees when they are too ill to earn a paycheck.',
            equal: 'This portion of your STD coverage is in line with other employers. You have done a good job of creating a benefit that helps meet the needs of your employees while remaining competitive with other organizations.',
            worse: 'This portion of your STD coverage is less than what your competitors are offering. Consider a longer sickness elimination period to still provide competitive coverage for your employees while helping to reduce costs.'
        }
    },
    'Long-term disability insurance': {
        benefitPercent: {
            better: 'Organizations comparable to yours sometimes offer a lower benefit percent in their LTD coverage. You may want to consider decreasing this benefit percent to reduce cost and align your coverage with other employers.',
            equal: 'The benefit percent you\'re currently offering is in line with what is offered by other employers. You have done a good job creating a benefit that helps meet the income replacement needs of your employees and is in alignment with other organizations.',
            worse: 'Organizations similar to yours typically offer a higher benefit percent on their LTD coverage. You may want to consider increasing the benefit percent to be more in line with other employers and to provide a higher level of income replacement for your employees.'
        },
        maximumMonthlyBenefit: {
            better: 'Your current LTD maximum benefit is higher than what is offered by many other organizations. Consider evaluating the benefit amount and potential impact to employees. If it is higher than needed, reducing this amount may lead to reduced costs.',
            equal: 'The maximum benefit you\'re currently offering is in line with what is being offered by other employers. You have created coverage that not only helps meet the income replacement needs of your employees but is also in alignment with other similar organizations.',
            worse: 'You’re currently offering an LTD maximum benefit that is below what many other organizations are offering. You may want to consider evaluating this amount to ensure your employees\' income replacement needs will be satisfied in the event of a disability.'
        },
        benefitDuration: {
            better: 'The benefit duration on your current design is longer than what many other organizations similar to yours are offering. You may want to consider decreasing your design\'s benefit duration to still provide income protection but for a shorter period of time and at a potential reduction in costs.',
            equal: 'The benefit duration you\'re currently offering is consistent with what is being offered by other employers. The design you have created helps meet the long-term disability income replacement needs of your employees and is also in alignment with other organizations.',
            worse: 'The benefit duration on your current design is less than what other organizations are offering. The duration of income replacement is an important part in building competitive disability coverage. Consider increasing your design\'s benefit duration to provide income protection for the duration of your employee\'s working years.'
        },
        eliminationPeriod: {
            better: 'The elimination period of your current design is longer than what many other organizations are offering. Providing income replacement after an appropriate period is an important part of building disability coverage. You may want to consider shortening your design\'s elimination period to provide income protection sooner for your employees.',
            equal: 'The elimination period you\'re currently offering is in line with other employers. You have done a good job creating a competitive design that helps meet the income replacement needs of your employees and is comparable with other organizations.',
            worse: 'The elimination period of your current design is shorter than what other organizations are offering. Providing income replacement after an appropriate period is an important part in building disability coverage, but you may be able to lengthen the period and reduce your costs.'
        },
        ownOccupationPeriod: {
            better: 'Your own occupation period is longer than what other similar organizations are offering their employees. You may want to consider shortening the own occupation period to more closely align with the competition.',
            equal: 'The own occupation period you\'re currently offering is in line with what is offered by other employers. You have done a good job creating a design that helps meet the disability income replacement needs of your employees and is in alignment with other organizations.',
            worse: 'Similar organizations typically provide own occupation coverage through the end of benefit duration. This is a particularly important aspect of an LTD contract for highly skilled, highly educated individuals. Consider providing own occupation coverage for the duration of the benefit to enhance coverage for these individuals.'
        }
    },
    'Retirement plan': {
        participationRate: {
            better: 'You should celebrate. Your plan\'s average participation rate is higher than organizations similar to yours. If you have a few key employees who may need to save more to replace their income in retirement, consider talking to your financial professional about adding a nonqualified deferred compensation (NQDC) plan.',
            equal: 'Kudos to you. Your plan\'s average participation rate is similar to organizations like yours. Want to get ahead of your peers? Talk to your financial professional about making changes to your plan design or enrollment and education strategy to help encourage more eligible employees to participate.',
            worse: 'You’re doing the right thing by offering a plan, but your employees may not be taking advantage of it like they should. Organizations similar to yours have a higher participation rate. You may want to consider talking to your financial professional about making changes to your plan design or enrollment and education strategy to help encourage more eligible employees to participate.'
        },
        deferralRate: {
            better: 'Good news, your employees are deferring more to retirement than employees at organizations like yours. Since the IRS sets limits on how much employees can contribute to a retirement plan, some highly compensated employees still might not be saving enough for retirement. Talk to your financial professional about how your top talent might benefit from a nonqualified deferred compensation (NQDC) plan.',
            equal: 'Keep up the good work. Your employees are deferring a similar amount to workers at organizations like yours. You can still boost deferral rates and better help your employees save enough for retirement. Consider talking to your financial professional about adding or enhancing existing plan features that may help boost deferrals such as automatic enrollment, setting the default deferral at 6% or more, automatic escalation and using an employer match formula that helps encourage employees to contribute more.',
            worse: 'Employees at organizations like yours are deferring more than your workers. If your plan doesn\'t have it already, you may benefit from using automatic enrollment with a 6% elective deferral default. Other plan sponsors have seen success by changing the match structure to increase participant deferrals or adding automatic escalation, which annually automatically increases participants’ elective deferral rates.'
        },
        automaticEnrollment: {
            better: '',
            equal: '',
            worse: 'Employers similar to you recognize automatic enrollment is an important step to help encourage plan participation. You may want to consider talking to your financial professional about adding automatic enrollment to help "nudge" employees into the plan to help them save for retirement. As a best practice, consider automatically enrolling eligible participants at 6% of eligible pay or higher.'
        },
        automaticEnrollmentSweep: {
            better: '',
            equal: '',
            worse: 'Other organizations like yours are using automatic enrollment sweeps to help encourage employees to participate and save more for retirement. You may want to consider adding a sweep to your plan. Talk to your financial professional about how automatic enrollment sweeps could help improve plan participation and deferral rates.'
        },
        automaticEscalation: {
            better: '',
            equal: '',
            worse: 'Your peers are using automatic escalation to help encourage employees to increase their deferrals so they\'re saving enough for retirement. To remain competitive with other employers in your area, you may want to consider talking to your financial professional about adding automatic escalation to your plan.'
        },
        employerMatch: {
            better: '',
            equal: '',
            worse: 'You\'ve got some room for improvement. Employees working for your peers are benefitting from an employer match. It’s a great way to encourage employees to save more because no one wants to feel like they\'re leaving money on the table. Make sure you\'re remaining competitive to attract and retain talent. Talk to your financial professional today about adding an employer match to improve savings rates and help workers achieve better outcomes.'
        }
    }
};
