import React from 'react';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const EnterpriseHeader = () => {
    return (
        <nav className='pds-navigationPrimary pds-navigationPrimary-primary pds-enterpriseHeader'>
            <div className='pds-navigationPrimary-container container'>
                <div className='pds-navigationPrimary-logo'>
                    <a
                       className='pds-navigationPrimary-logo-link pds-link-unstyled'
                       href='https://www.principal.com/'
                       aria-label='Principal Homepage'
                       onClick={() => GoogleAnalyticsUtil.textLink('Principal Homepage')}
                    >
                        <img alt='' className='pds-imgLogoCompanyNameWhiteRetina'/>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default EnterpriseHeader;
