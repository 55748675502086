import React from 'react';
import DropDownField from './common/DropDownField';
import {useDispatch, useSelector} from 'react-redux';
import {appendString, numeric} from '../../util/StringUtil';
import {UPDATE_RETIREMENT} from '../../redux/reducer/RetirementReducer';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {YES, yesNoOptions} from '../../properties/yesNoOptions';
import Button from './common/Button';
import PercentField from './common/PercentField';
import Modal from './common/Modal';
import {saveData} from '../../redux/action/SaveDataAction';
import Tooltip from './common/Tooltip';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const Retirement = () => {

    const dispatch = useDispatch();
    const reduxState = useSelector(stateSelector);
    const [state, setState] = React.useState({
        participationRate: reduxState.retirement.participationRate,
        deferralRate: reduxState.retirement.deferralRate,
        automaticEnrollment: reduxState.retirement.automaticEnrollment,
        automaticEnrollmentSweep: reduxState.retirement.automaticEnrollmentSweep,
        automaticEscalation: reduxState.retirement.automaticEscalation,
        employerMatch: reduxState.retirement.employerMatch
    });

    const render = () => {
        return (
            <div className='noPrint'>
                <Modal
                    id='retirementForm'
                    buttonText='Compare your plan'
                    headerText='401(k) or 403(b) retirement plan:'
                    srText='retirement'
                    title='401(k) or 403(b) retirement plan'
                >
                    <div style={{textAlign: 'left', marginTop: '40px'}}>
                        <PercentField
                            id='RetirementParticipationRate'
                            labelText={'What\'s the participation rate of your plan?'}
                            value={state.participationRate}
                            onChange={(e) => setState({...state, participationRate: numeric(e.target.value)})}
                            tooltip={<Tooltip content={'The number of participants deferring in the plan divided by the number eligible to defer.'}/>}
                        />
                        <PercentField
                            id='RetirementAverageDeferralRate'
                            labelText={'What\'s your plan\'s average deferral rate?'}
                            value={state.deferralRate}
                            onChange={(e) => setState({...state, deferralRate: numeric(e.target.value)})}
                            tooltip={<Tooltip content={'The average elective deferral made by participants currently contributing to the plan.'}/>}
                        />
                        <DropDownField
                            id='RetirementAutomaticEnrollment'
                            options={yesNoOptions}
                            value={state.automaticEnrollment}
                            isValid={true}
                            labelText={'Does your plan use an automatic enrollment feature?'}
                            onChange={(e) => setState({
                                ...state,
                                automaticEnrollment: e.target.value,
                                automaticEnrollmentSweep: undefined,
                                automaticEscalation: undefined
                            })}
                            tooltip={<Tooltip content={'With automatic enrollment, employers set eligibility requirements and pick the default deferral rate. A suggested best practice is to set the default deferral rate at 6% or more to help workers reach a savings rate we believe can help.'}/>}
                        />
                        {state.automaticEnrollment === YES && automaticEnrollmentFeatureQuestions()}
                        <DropDownField
                            id='RetirementEmployerMatch'
                            options={yesNoOptions}
                            value={state.employerMatch}
                            isValid={true}
                            labelText={'Does your plan include an employer match?'}
                            onChange={(e) => setState({...state, employerMatch: e.target.value})}
                            tooltip={<Tooltip content={'An employer match is the percentage or dollar amount of an employee\'s contributions — up to a certain dollar amount — that an employer contributes. Offering a matching contribution is one way to help improve savings rates.'}/>}
                        />
                    </div>
                    <Button
                        className='pds-button pds-button-primary pds-modal-close-dialog'
                        onClick={() => {
                            GoogleAnalyticsUtil.modalClickAction('401(k) or 403(b) retirement plan', 'Submit');
                            dispatch(saveData(
                                {
                                    type: UPDATE_RETIREMENT,
                                    data: {
                                        participationRate: appendString(state.participationRate, '%'),
                                        deferralRate: appendString(state.deferralRate, '%'),
                                        automaticEnrollment: state.automaticEnrollment,
                                        automaticEnrollmentSweep: state.automaticEnrollmentSweep,
                                        automaticEscalation: state.automaticEscalation,
                                        employerMatch: state.employerMatch,
                                        product: 'Retirement'
                                    }
                                }
                            ));
                        }}
                    ><span className='sr-only'>Retirement form submit</span>Submit</Button>
                </Modal>
            </div>
        );
    };

    const automaticEnrollmentFeatureQuestions = () => {
        return <>
            <DropDownField
                id='RetirementAutomaticEnrollmentSweep'
                options={yesNoOptions}
                value={state.automaticEnrollmentSweep}
                isValid={true}
                labelText={'Does your plan use an automatic enrollment sweep feature?'}
                onChange={(e) => setState({...state, automaticEnrollmentSweep: e.target.value})}
                tooltip={<Tooltip content={'Automatic reenrollment sweep means employers automatically enroll non-participants and increase deferrals for everyone contributing less than the automatic enrollment default deferral.'}/>}
            />
            <DropDownField
                id='RetirementAutomaticEnrollmentEscalation'
                options={yesNoOptions}
                value={state.automaticEscalation}
                isValid={true}
                labelText={'Does your plan use automatic escalation?'}
                onChange={(e) => setState({...state, automaticEscalation: e.target.value})}
                tooltip={<Tooltip content={'Automatic escalation helps participants gradually increase contributions over time. A suggested best practice is to automatically increase contributions by at least 1% per year up to at least 10%.'}/>}
            />
        </>;
    };

    return render();
};

export default Retirement;
