import React from 'react';

const FormError = (props) => {

    return (
        <div className='pds-error'>
            <span className='pds-util-flex-align-center'>
                    {props.errorText}
            </span>
        </div>
    );
};

export default FormError;
