import React from 'react';
import DropDownField from './common/DropDownField';
import {useDispatch, useSelector} from 'react-redux';
import Input from './common/Input';
import {appendString, numeric} from '../../util/StringUtil';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {benefitPercentageOptions} from '../../properties/benefitPercentageOptions';
import {UPDATE_SHORT_TERM_DISABILITY} from '../../redux/reducer/ShortTermDisabilityReducer';
import Button from './common/Button';
import CurrencyField from './common/CurrencyField';
import CurrencyUtil from '../../util/CurrencyUtil';
import Modal from './common/Modal';
import {saveData} from '../../redux/action/SaveDataAction';
import Tooltip from './common/Tooltip';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const ShortTermDisability = () => {

    const dispatch = useDispatch();
    const reduxState = useSelector(stateSelector);
    const [state, setState] = React.useState({
        benefitPercent: reduxState.shortTermDisability.benefitPercent,
        maximumWeeklyBenefit: numeric(reduxState.shortTermDisability.maximumWeeklyBenefit),
        benefitDuration: numeric(reduxState.shortTermDisability.benefitDuration),
        accidentEliminationPeriod: numeric(reduxState.shortTermDisability.accidentEliminationPeriod),
        sicknessEliminationPeriod: numeric(reduxState.shortTermDisability.sicknessEliminationPeriod)
    });

    return (
        <div className='noPrint'>
            <Modal
                id='shortTermDisabilityForm'
                buttonText='Compare your insurance'
                headerText='Short-term disability insurance:'
                srText='short term disability'
                title='Short-term disability insurance'
            >
                <div style={{textAlign: 'left', marginTop: '40px'}}>
                    <DropDownField
                        id='ShortTermDisabilityBenefitPercentageDropdown'
                        options={benefitPercentageOptions}
                        value={state.benefitPercent}
                        isValid={true}
                        labelText={'What\'s the benefit percent?'}
                        onChange={(e) => setState({...state, benefitPercent: e.target.value})}
                    />
                    <CurrencyField
                        id='ShortTermDisabilityMaximumWeeklyBenefitInput'
                        labelText={'What\'s the maximum weekly benefit?'}
                        value={state.maximumWeeklyBenefit}
                        onChange={(value) => setState({...state, maximumWeeklyBenefit: CurrencyUtil.formatUSD(value)})}
                        tooltip={<Tooltip content={'This is the maximum dollar amount the insurance company pays to a disabled employee. A common maximum weekly benefit is $1,500.'}/>}
                    />
                    <Input
                        id='ShortTermDisabilityBenefitBenefitDurationInput'
                        value={state.benefitDuration}
                        isValid={true}
                        maxLength={3}
                        labelText={'What\'s the benefit duration in weeks (must be 8-52 weeks)?'}
                        onChange={(e) => setState({...state, benefitDuration: numeric(e.target.value)})}
                        tooltip={<Tooltip content={'This is the maximum length of time the benefit will be paid.'}/>}
                    />
                    <Input
                        id='ShortTermDisabilityAccidentEliminationPeriodInput'
                        value={state.accidentEliminationPeriod}
                        isValid={true}
                        maxLength={3}
                        labelText={'What\'s the accident elimination period in days (must be 1-90 days)?'}
                        onChange={(e) => setState({...state, accidentEliminationPeriod: numeric(e.target.value)})}
                        tooltip={<Tooltip content={'This is the amount of time employees on disability need to wait before they’re eligible to receive benefit payments. The accident elimination period is specific to disabilities that occur because of an accidental injury.'}/>}
                    />
                    <Input
                        id='ShortTermDisabilitySicknessEliminationPeriodInput'
                        value={state.sicknessEliminationPeriod}
                        isValid={true}
                        maxLength={3}
                        labelText={'What\'s the sickness elimination period in days (must be 1-90 days)?'}
                        onChange={(e) => setState({...state, sicknessEliminationPeriod: numeric(e.target.value)})}
                        tooltip={<Tooltip content={'This is the amount of time employees on disability need to wait before they’re eligible to receive benefit payments. The sickness elimination period is specific to disabilities occurring because of disease or pregnancy.'}/>}
                    />
                </div>
                <Button
                    className='pds-button pds-button-primary pds-modal-close-dialog'
                    onClick={() => {
                        GoogleAnalyticsUtil.modalClickAction('Short-term disability insurance', 'Submit');
                        dispatch(saveData(
                            {
                                type: UPDATE_SHORT_TERM_DISABILITY,
                                data: {
                                    benefitPercent: state.benefitPercent,
                                    maximumWeeklyBenefit: state.maximumWeeklyBenefit,
                                    benefitDuration: appendString(state.benefitDuration, ' week(s)'),
                                    accidentEliminationPeriod: appendString(state.accidentEliminationPeriod, ' day(s)'),
                                    sicknessEliminationPeriod: appendString(state.sicknessEliminationPeriod, ' day(s)'),
                                    product: 'Short-term disability'
                                }
                            }
                        ));
                    }}
                ><span className='sr-only'>STD insurance submit</span>Submit</Button>
            </Modal>
        </div>
    );
};

export default ShortTermDisability;
