import React from 'react';

const Button = (props) => {
    return <button
        id={props.id}
        style={{marginTop: '40px'}}
        className={props.className ? props.className : 'pds-button pds-button-primary pds-util-float-right noPrint'}
        onClick={props.onClick}
    >
        {props.back && <i data-feather='chevron-left' data-size='24' aria-hidden='true'/>}
        {props.children}
        {props.next && <i data-feather='chevron-right' data-size='24' aria-hidden='true'/>}
    </button>;
};

export default Button;