export const OWNER = 'Business owner/employer';
export const FINANCIAL_PROFESSIONAL = 'Financial professional';
export const HUMAN_RESOURCES = 'Human resources';
export const OTHER = 'Other';

export const roles = [
    {display: 'Please select', value: ''},
    {display: OWNER, value: OWNER},
    {display: FINANCIAL_PROFESSIONAL, value: FINANCIAL_PROFESSIONAL},
    {display: HUMAN_RESOURCES, value: HUMAN_RESOURCES},
    {display: OTHER, value: OTHER}
];