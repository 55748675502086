export const CONSTRUCTION = 'Construction';
export const EDUCATION = 'Education';
export const ENGINEERING_ACCOUNTING_CONSULTING = 'Engineering/Accounting/Consulting';
export const FINANCE_INSURANCE_REAL_ESTATE = 'Finance/Insurance/Real Estate';
export const HEALTHCARE = 'Healthcare';
export const LEGAL = 'Legal';
export const MANUFACTURING = 'Manufacturing';
export const PROFESSIONAL_SERVICES = 'Professional Services';
export const PUBLIC_ADMINISTRATION_UTILITIES = 'Public Administration/Utilities';
export const RETAIL_TRADE = 'Retail';
export const SOCIAL_SERVICES = 'Social Services';
export const TRANSPORTATION_WAREHOUSING = 'Transportation/Warehousing';
export const UTILITIES_COMMUNICATION = 'Utilities/Communication';

export const industries = [
    {display: 'Please select', value: ''},
    {display: CONSTRUCTION, value: CONSTRUCTION},
    {display: EDUCATION, value: EDUCATION},
    {display: ENGINEERING_ACCOUNTING_CONSULTING, value: ENGINEERING_ACCOUNTING_CONSULTING},
    {display: FINANCE_INSURANCE_REAL_ESTATE, value: FINANCE_INSURANCE_REAL_ESTATE},
    {display: HEALTHCARE, value: HEALTHCARE},
    {display: LEGAL, value: LEGAL},
    {display: MANUFACTURING, value: MANUFACTURING},
    {display: PROFESSIONAL_SERVICES, value: PROFESSIONAL_SERVICES},
    {display: PUBLIC_ADMINISTRATION_UTILITIES, value: PUBLIC_ADMINISTRATION_UTILITIES},
    {display: RETAIL_TRADE, value: RETAIL_TRADE},
    {display: SOCIAL_SERVICES, value: SOCIAL_SERVICES},
    {display: TRANSPORTATION_WAREHOUSING, value: TRANSPORTATION_WAREHOUSING},
    {display: UTILITIES_COMMUNICATION, value: UTILITIES_COMMUNICATION}
];

