import React from 'react';
import './style/App.css';
import {ErrorBoundary} from './ErrorBoundary';
import {Router} from 'react-router-dom';
import Routes from './route/Routes';

const App = ({history}) => {
    return (
        <ErrorBoundary history={history}>
            <Router history={history}>
                <Routes/>
            </Router>
        </ErrorBoundary>
    );
};

export default App;
