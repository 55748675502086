export const FLAT_BENEFIT = 'Flat benefit';
export const PERCENT_OF_SALARY_ONE_HUNDRED = '100% of salary';
export const PERCENT_OF_SALARY_TWO_HUNDRED = '200% of salary';
export const PERCENT_OF_SALARY_THREE_HUNDRED = '300% of salary';
export const PERCENT_OF_SALARY_FOUR_HUNDRED = '400% of salary';
export const PERCENT_OF_SALARY_FIVE_HUNDRED = '500% of salary';

export const employeeBenefitSchedules = [
    {display: 'Please select', value: ''},
    {display: FLAT_BENEFIT, value: FLAT_BENEFIT},
    {display: PERCENT_OF_SALARY_ONE_HUNDRED, value: PERCENT_OF_SALARY_ONE_HUNDRED},
    {display: PERCENT_OF_SALARY_TWO_HUNDRED, value: PERCENT_OF_SALARY_TWO_HUNDRED},
    {display: PERCENT_OF_SALARY_THREE_HUNDRED, value: PERCENT_OF_SALARY_THREE_HUNDRED},
    {display: PERCENT_OF_SALARY_FOUR_HUNDRED, value: PERCENT_OF_SALARY_FOUR_HUNDRED},
    {display: PERCENT_OF_SALARY_FIVE_HUNDRED, value: PERCENT_OF_SALARY_FIVE_HUNDRED}
];