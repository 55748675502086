export const UPDATE_DENTAL_INSURANCE = 'UPDATE_DENTAL_INSURANCE';
const defaultState = {};

const dentalInsuranceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_DENTAL_INSURANCE:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default dentalInsuranceReducer;