import {childBenefitOptions} from './childBenefitOptions';

export const THIRTY_THOUSAND = '$30,000';
export const THIRTY_FIVE_THOUSAND = '$35,000';
export const FORTY_THOUSAND = '$40,000';
export const FORTY_FIVE_THOUSAND = '$45,000';
export const FIFTY_THOUSAND = '$50,000';

export const spouseBenefitOptions = [
    ...childBenefitOptions,
    {display: THIRTY_THOUSAND, value: THIRTY_THOUSAND},
    {display: THIRTY_FIVE_THOUSAND, value: THIRTY_FIVE_THOUSAND},
    {display: FORTY_THOUSAND, value: FORTY_THOUSAND},
    {display: FORTY_FIVE_THOUSAND, value: FORTY_FIVE_THOUSAND},
    {display: FIFTY_THOUSAND, value: FIFTY_THOUSAND}
];

