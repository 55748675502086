import React from 'react';

export const GENERIC_ERROR_MESSAGE = 'It looks like something went wrong on our end. We are working on getting that fixed';

const Error = () => {
    return <div className='container' style={{position: 'relative'}}>
        <div className='text-center'>
            <div>
                <h1>We're sorry.</h1>
            </div>
            <div>
                <p>{GENERIC_ERROR_MESSAGE}</p>
            </div>
        </div>
        <img src='https://ux.principal.com/horizon/master/examplefiles/v2/caleb-the-plumber.svg'/>
    </div>;
};

export default Error;