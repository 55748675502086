export const UPDATE_RETIREMENT = 'RETIREMENT';
const defaultState = {};

const retirementReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_RETIREMENT:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default retirementReducer;