import React from 'react';
import Error from './view/Error';
import EnterpriseHeader from './bookend/EnterpriseHeader';
import EnterpriseFooter from './bookend/EnterpriseFooter';

const ErrorPage = () => {
    return <div className='container'>
        <EnterpriseHeader/>
        <Error/>
        <EnterpriseFooter/>
    </div>;
};

export default ErrorPage;