import React from 'react';
import RequiredFieldIndicator from './RequiredFieldIndicator';
import FormError from './FormError';

const DropDownField = (props) => {
    return (
        <div style={{maxWidth: '400px', marginBottom: '20px'}}>
            <div className='pds-selectWrapper'>
                <div className='pds-labeledSelect'>
                    <label htmlFor={props.id}>
                        {props.labelText}
                        {props.isRequired && <RequiredFieldIndicator/>}
                        {props.tooltip && props.tooltip}
                    </label>
                    <div className='pds-select'>
                        <select
                            aria-label={props.labelText}
                            id={`${props.id}Select`}
                            required
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            value={props.value}
                        >
                            {buildOptions(props)}
                        </select>
                        <i data-feather='chevron-down' data-size='24' aria-hidden='true'/>
                    </div>
                </div>
                {!props.isValid &&
                    <FormError errorText={props.errorText}/>
                }
            </div>
        </div>
    );
};

const buildOptions = (props) => {
    return props.options.map(option => {
        return (
            <option key={`select-${option.value}`} value={option.value}>
                {option.display}
            </option>
        );
    });
};

export default DropDownField;
